import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Box } from "@mui/system";
import { styled } from "@mui/system";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

import NewHeader from "../components/layout/NewHeader";
import NewFooter from "../components/layout/NewFooter";
import TokensTable from "../components/TokensTable";
import { getCurrentWalletConnected, connectWallet } from "../utils/interact";
import { getTokensOwnedByWalletAddress } from "../services/contract";

const { chainIdToNetworkParams } = require("../services/networks");
const CHAIN_ID = process.env.REACT_APP_CHAIN_ID;

const Inventory = () => {
  const { t } = useTranslation();
  const [walletAddress, setWallet] = useState("");

  const [tokens, setTokens] = useState([]);
  const [tokensCount, setTokensCount] = useState(0);

  const [loading, setLoading] = useState(true);
  const [rightNetwork, setRightNetwork] = useState(false);
  const [status, setStatus] = useState("");

  const checkConnectedChainId = async () => {
    const currentChainId = await window.ethereum.request({
      method: "eth_chainId",
    });
    // console.log(currentChainId, parseInt(CHAIN_ID));
    if (currentChainId == parseInt(CHAIN_ID)) {
      setRightNetwork(true);
    } else {
      setRightNetwork(false);
    }
  };

  const addWalletListener = () => {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", async (accounts) => {
        setTokens([]);
        if (accounts.length > 0) {
          setWallet(accounts[0]);
          await checkConnectedChainId();
          setStatus(t("clickMintButton"));
        } else {
          setWallet("");
          setStatus(`🦊 ${t("connectMetaMask")}`);
        }
      });

      window.ethereum.on("chainChanged", (chainId) => {
        setTokens([]);
        chainId = parseInt(chainId, 16);
        // console.log(chainId, parseInt(CHAIN_ID));
        if (chainId == parseInt(CHAIN_ID)) {
          setRightNetwork(true);
        } else {
          setRightNetwork(false);
        }
      });
    } else {
      setStatus(
        <p>
          {" "}
          🦊{" "}
          <a target="_blank" href={`https://metamask.io/download.html`}>
            {t("youMustInstallMetaMask")}
          </a>
        </p>
      );
    }
  };

  useEffect(() => {
    const init = async () => {
      addWalletListener();
      const { address } = await getCurrentWalletConnected();
      console.log(address);
      if (address) {
        await checkConnectedChainId();
      }
    };
    init();
  }, []);

  const StyledConnectWalletButton = styled(Button)(({ theme }) => ({
    backgroundColor: "#000",
    color: "#fff",
    fontWeight: 1000,
    fontSize: "28px",
    padding: ".3em 1.5em",
    borderRadius: "50px",
    "&:disabled": {
      color: "#adadad",
    },
    "&:hover": {
      backgroundColor: "#ff5254",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "22px",
      padding: ".2em 2em",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "22px",
      padding: ".3em 2em",
    },
  }));

  const getOwnedTokens = async (walletAddress) => {
    setLoading(true);
    try {
      const _tokens = await getTokensOwnedByWalletAddress(walletAddress);
      setTokensCount(_tokens.length);
      setTokens(_tokens);
    } catch (e) {
      setTokens([]);
    }
    setLoading(false);
  };

  const connectWalletPressed = async () => {
    const walletResponse = await connectWallet();
    console.log(walletResponse);
    setWallet(walletResponse.address);
    if (walletResponse.address.length > 0) {
      await getOwnedTokens(walletResponse.address);
    }
  };

  return (
    <Box>
      <NewHeader />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          minHeight: "80vh",
          paddingTop: "200px",
        }}
      >
        {walletAddress && rightNetwork ? (
          <>
            {loading && (
              <div
                style={{
                  textAlign: "center",
                  marginTop: "50px",
                  color: "black",
                  display: "flex",
                  alignItems: "center",
                  gap: "20px",
                }}
              >
                <CircularProgress />
                <span>{t("loading")}</span>
              </div>
            )}

            {tokensCount === 0 && !loading && (
              <div
                style={{
                  textAlign: "center",
                  marginTop: "50px",
                  color: "black",
                }}
              >
                {t("noResult")}
              </div>
            )}

            {tokensCount > 0 && !loading && (
              <div style={{ overflow: "auto", maxWidth: "100%" }}>
                <TokensTable tokens={tokens} />
              </div>
            )}
          </>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <StyledConnectWalletButton onClick={connectWalletPressed}>
              {!walletAddress && t("connectWallet")}
              {walletAddress &&
                !rightNetwork &&
                t("connectToNetwork", {
                  chainName: chainIdToNetworkParams[CHAIN_ID]["chainName"],
                })}
            </StyledConnectWalletButton>
          </div>
        )}
      </div>
      <NewFooter />
    </Box>
  );
};

export default Inventory;
