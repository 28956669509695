import * as React from "react";

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Grid, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import LaligaLand from "../../../assets/images/laligalandLogo.svg";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 450,
  minWidth: 300,
  padding: "40px",
  borderRadius: "0.5rem",
  backgroundImage:
    "linear-gradient(80deg, rgb(255, 179, 85) 0%, rgb(122 29 45) 100%)",
  boxShadow: 24,
  color: "#000",
  "&:focus": {
    border: "none",
    outline: "none",
  },
  fontWeight: "bold",
};

const MintErrorModal = ({ statusMessage, openModal, setOpenModal }) => {
  const handleClose = () => {
    setOpenModal(false);
  };

  return (
    <div>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container position="relative">
            <Grid item sm="12" justifyContent="center" display="flex">
              <img src={LaligaLand} alt="laliga" />
            </Grid>
            <Grid item sm="1" position="absolute" top="-20px" right="-25px">
              <Typography
                onClick={handleClose}
                sx={{
                  display: "block",
                  paddingBottom: "10px",
                  float: "right",
                  color: "white",
                  cursor: "pointer",
                  "&:hover": { color: "#ffb355" },
                }}
              >
                <div>
                  <CloseIcon />
                </div>
              </Typography>
            </Grid>
          </Grid>
          <br />
          <Typography
            onClick={handleClose}
            sx={{
              textAlign: "center",
            }}
          >
            {statusMessage}
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};

export default MintErrorModal;
