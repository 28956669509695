import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Box } from "@mui/system";

import NewHeader from "../components/layout/NewHeader";
import NewFooter from "../components/layout/NewFooter";

const NotFound = () => {
  const { t } = useTranslation();

  return (
    <Box>
      <NewHeader />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          minHeight: "80vh",
          paddingTop: "200px",
        }}
      >
        <h1 style={{ textAlign: "center" }}>
          404
          <br />
          {t("pageNotFound")}
        </h1>
        <h3>
          <Link to="/">{t("goToHome")}</Link>
        </h3>
      </div>
      <NewFooter />
    </Box>
  );
};

export default NotFound;
