import React, { useRef, useState, useEffect } from "react";
import { useTranslation, Trans } from "react-i18next";

import { Box } from "@mui/system";
import { styled } from "@mui/system";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

import NewHeader from "../components/layout/NewHeader";
import NewFooter from "../components/layout/NewFooter";
import MintTable from "../components/MintTable";
import { getCurrentWalletConnected, connectWallet } from "../utils/interact";
import MintErrorModal from "../components/sections/partials/MintErrorModal";
import {
  registerAsReferrer,
  getReferralIdFromReferrer,
  getMintsByReferralId,
} from "../services/contract";

const { chainIdToNetworkParams } = require("../services/networks");
const CHAIN_ID = process.env.REACT_APP_CHAIN_ID;

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ maxWidth: "100%" }}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

const Welcome = () => {
  const { t, i18n } = useTranslation();
  const StyledP = styled("p")(() => ({
    fontSize: "20px",
    textAlign: "center",
    color: "white",
    whiteSpace: "pre-line",
    "& a": {
      color: "white !important",
      textDecoration: "underline",
    },
  }));

  return (
    <div style={{ margin: "auto", maxWidth: "600px" }}>
      <h2 style={{ fontSize: "30px", textAlign: "center", color: "white" }}>
        {t("welcomeToReferrer")}
      </h2>
      <StyledP>
        <Trans
          i18nKey="welcomeToReferrerMessage"
          values={{ lang: i18n.language }}
          components={[<a />]}
        />
      </StyledP>
    </div>
  );
};

const Referrer = () => {
  const { t } = useTranslation();
  const [walletAddress, setWallet] = useState("");
  const [referrerId, setReferrerId] = useState("");
  const [referralCount, setReferralCount] = useState(0);
  const [mints, setMints] = useState([]);
  const [searching, setSearching] = useState(false);
  const [value, setValue] = useState(0);
  const [registering, setRegistering] = useState(false);
  const [walletReferrerId, setWalletReferrerId] = useState(undefined);
  const [rightNetwork, setRightNetwork] = useState(false);
  const [status, setStatus] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const inputRef = useRef(null);

  const checkConnectedChainId = async () => {
    const currentChainId = await window.ethereum.request({
      method: "eth_chainId",
    });
    // console.log(currentChainId, parseInt(CHAIN_ID));
    if (currentChainId == parseInt(CHAIN_ID)) {
      setRightNetwork(true);
    } else {
      setRightNetwork(false);
    }
  };

  const addWalletListener = () => {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", async (accounts) => {
        if (accounts.length > 0) {
          setWallet(accounts[0]);
          await checkConnectedChainId();
          setStatus(t("clickMintButton"));
        } else {
          setWallet("");
          setStatus(`🦊 ${t("connectMetaMask")}`);
        }
      });

      window.ethereum.on("chainChanged", (chainId) => {
        chainId = parseInt(chainId, 16);
        // console.log(chainId, parseInt(CHAIN_ID));
        if (chainId == parseInt(CHAIN_ID)) {
          setRightNetwork(true);
        } else {
          setRightNetwork(false);
        }
      });
    } else {
      setStatus(
        <p>
          {" "}
          🦊{" "}
          <a target="_blank" href={`https://metamask.io/download.html`}>
            {t("youMustInstallMetaMask")}
          </a>
        </p>
      );
    }
  };

  useEffect(() => {
    const init = async () => {
      addWalletListener();
      const { address } = await getCurrentWalletConnected();
      console.log(address);
      if (address) {
        await checkConnectedChainId();
        const referralId = await getReferralIdFromReferrer(address);
        setWalletReferrerId(referralId);
        await getMintsByReferralId(referralId);
      }
    };
    init();
  }, []);

  const handleCopyLink = () => {
    navigator.clipboard.writeText(
      `https://shop.laligaland.io/?referrer=${walletReferrerId}`
    );
    setSnackbarOpen(true);
  };

  const ReferrerButton = styled(Button)(({ theme }) => ({
    backgroundColor: "#000",
    color: "#fff",
    fontWeight: 1000,
    fontSize: "28px",
    padding: ".3em 1.5em",
    borderRadius: "50px",
    width: "100%",
    "&:disabled": {
      color: "#adadad",
    },
    "&:hover": {
      backgroundColor: "#ff5254",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "22px",
      padding: ".2em 2em",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "22px",
      padding: ".3em 2em",
    },
  }));
  const StyledButton = styled(Button)(({ theme }) => ({
    backgroundColor: "#000",
    color: "#fff",
    fontWeight: 1000,
    fontSize: "28px",
    padding: ".3em 1.5em",
    borderRadius: "50px",
    "&:disabled": {
      color: "#adadad",
    },
    "&:hover": {
      backgroundColor: "#ff5254",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "22px",
      padding: ".2em 2em",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "22px",
      padding: ".3em 2em",
      width: "130px",
    },
  }));
  const StyledInput = styled("input")(({ theme }) => ({
    fontFamily: "sans-serif",
    width: "350px",
    border: "none",
    outline: "none",
    fontSize: "35px",
    padding: "10px 20px",
    borderRadius: "3px",
    marginRight: "15px",
    [theme.breakpoints.down("md")]: {
      width: "180px",
    },
  }));
  const StyledTabs = styled(Tabs)(() => ({
    "& .Mui-selected": {
      fontWeight: "bold",
    },
  }));
  const StyledConnectWalletButton = styled(Button)(({ theme }) => ({
    backgroundColor: "#000",
    color: "#fff",
    fontWeight: 1000,
    fontSize: "28px",
    padding: ".3em 1.5em",
    borderRadius: "50px",
    "&:disabled": {
      color: "#adadad",
    },
    "&:hover": {
      backgroundColor: "#ff5254",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "22px",
      padding: ".2em 2em",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "22px",
      padding: ".3em 2em",
    },
  }));

  const searchByRefererId = async () => {
    const inputValue = inputRef.current.value;
    if (!inputValue) return;
    setReferrerId(inputValue);
    setSearching(true);
    try {
      const _referees = await getMintsByReferralId(inputValue);
      setReferralCount(_referees.length);
      setMints(_referees);
    } catch (e) {
      setReferralCount(0);
      setMints([]);
    }
    setSearching(false);
  };
  const register = async () => {
    try {
      const tx = await registerAsReferrer();
      setRegistering(true);
      const txReceipt = await tx.wait();
      // console.log(txReceipt)
      // console.log(Object.keys(txReceipt))
      // console.log(txReceipt.events[0].topics[2])
      // console.log(Number(txReceipt.events[0].topics[2]))
      setWalletReferrerId(Number(txReceipt.events[0].topics[2]));
    } catch (err) {
      console.log(err);
    }
    setRegistering(false);
  };
  const handleTabChange = (_e, newVal) => {
    setValue(newVal);
  };
  const connectWalletPressed = async () => {
    const walletResponse = await connectWallet();
    setStatus(
      walletResponse.address
        ? "Please use register button to be a referrer"
        : walletResponse.status
    );
    console.log(walletResponse);
    setWallet(walletResponse.address);
    setOpenModal(true);
    if (walletResponse.address.length > 0) {
      const referralId = await getReferralIdFromReferrer(
        walletResponse.address
      );
      setWalletReferrerId(referralId);
      await getMintsByReferralId(referralId);
    }
  };

  return (
    <Box>
      <NewHeader />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          minHeight: "80vh",
          paddingTop: "200px",
        }}
      >
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <StyledTabs
            value={value}
            onChange={handleTabChange}
            aria-label="basic tabs example"
          >
            <Tab label={t("search")} id="search-referral" />
            <Tab label={t("register")} id="register-referrer" />
          </StyledTabs>
        </Box>
        <TabPanel value={value} index={0}>
          {walletAddress && rightNetwork ? (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <StyledInput
                  type="text"
                  placeholder="Referrer id"
                  disabled={searching}
                  ref={inputRef}
                />
                <StyledButton onClick={searchByRefererId} disabled={searching}>
                  {searching ? (
                    <span style={{ marginTop: "5px" }}>
                      <CircularProgress size="28px" />
                    </span>
                  ) : (
                    t("search")
                  )}
                </StyledButton>
              </div>

              {searching && (
                <div
                  style={{
                    textAlign: "center",
                    marginTop: "50px",
                    color: "black",
                  }}
                >
                  {t("searching")} ...
                </div>
              )}
              {referralCount === 0 && !searching && (
                <div
                  style={{
                    textAlign: "center",
                    marginTop: "50px",
                    color: "black",
                  }}
                >
                  {t("noResult")}
                </div>
              )}
              {referralCount > 0 && !searching && (
                <div style={{ overflow: "auto", maxWidth: "100%" }}>
                  <MintTable mints={mints} referralId={referrerId} />
                </div>
              )}
            </>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <StyledConnectWalletButton onClick={connectWalletPressed}>
                {!walletAddress && t("connectWallet")}
                {walletAddress &&
                  !rightNetwork &&
                  t("connectToNetwork", {
                    chainName: chainIdToNetworkParams[CHAIN_ID]["chainName"],
                  })}
              </StyledConnectWalletButton>
              <Welcome />
            </div>
          )}
        </TabPanel>
        <TabPanel value={value} index={1}>
          <div>
            {walletAddress && rightNetwork ? (
              walletReferrerId === undefined ? (
                <>
                  <h3>{t("becomeReferrer")}</h3>
                  <ReferrerButton onClick={register} disabled={registering}>
                    {registering ? (
                      <span style={{ marginTop: "5px" }}>
                        <CircularProgress size="28px" />
                      </span>
                    ) : (
                      t("register")
                    )}
                  </ReferrerButton>
                </>
              ) : (
                <>
                  <h3>{t("youAreReferrer")}</h3>
                  <h3>{t("yourReferralId", { walletReferrerId })}</h3>
                  <h3>
                    {t("yourReferralLink", {
                      referralLink: `https://shop.laligaland.io/?referrer=${walletReferrerId}`,
                    })}
                    <ContentCopyOutlinedIcon
                      style={{ cursor: "pointer" }}
                      onClick={handleCopyLink}
                    />
                  </h3>
                </>
              )
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <StyledConnectWalletButton onClick={connectWalletPressed}>
                  {!walletAddress && t("connectWallet")}
                  {walletAddress &&
                    !rightNetwork &&
                    t("connectToNetwork", {
                      chainName: chainIdToNetworkParams[CHAIN_ID]["chainName"],
                    })}
                </StyledConnectWalletButton>
                <Welcome />
              </div>
            )}
          </div>
        </TabPanel>
      </div>
      <MintErrorModal
        statusMessage={status}
        openModal={openModal}
        setOpenModal={setOpenModal}
      />
      <NewFooter />
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        autoHideDuration={3000}
      >
        <MuiAlert
          onClose={() => setSnackbarOpen(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          {t("linkCopied")}
        </MuiAlert>
      </Snackbar>
    </Box>
  );
};

export default Referrer;
