import React, { useLayoutEffect, useRef } from "react";
import { gsap } from "gsap";
import ScrollToPlugin from "gsap/dist/ScrollToPlugin";
import ScrollTrigger from "gsap/dist/ScrollTrigger";

import { Box } from "@mui/system";

import PurchaseBlock from "../components/sections/PurchaseBlock";
import NewHeader from "../components/layout/NewHeader";
import NewFooter from "../components/layout/NewFooter";

const Home = () => {
  const app = useRef();

  gsap.registerPlugin(ScrollTrigger);
  gsap.registerPlugin(ScrollToPlugin);

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      gsap.utils.toArray(".panel").forEach((panel, i) => {
        ScrollTrigger.create({
          trigger: panel,
          start: "top top",
          snap: true,
          pinSpacing: false,
        });
      });
    }, app);

    return () => ctx.revert();
  });

  return (
    <Box ref={app}>
      <NewHeader />
      <div className="panel" id="purchaseBlock">
        <PurchaseBlock />
      </div>
      <NewFooter />
    </Box>
  );
};

export default Home;
