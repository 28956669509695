import React from "react";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Button, Grid } from "@mui/material";
import { Box, styled } from "@mui/system";

import VegasCity from "../../assets/images/vegas-city-logo_1.png";
import TwitterLogo from "../../assets/images/twitter.svg";
import DiscordLogo from "../../assets/images/discord.svg";
import YoutubeLogo from "../../assets/images/youtube.svg";

const NewFooter = () => {
  const { t, i18n } = useTranslation();

  const StyledFooter = styled(Grid)(({ theme }) => ({
    width: "100%",
    height: "auto",
    backgroundColor: "#000",
    color: "white",
    margin: "auto",
    bottom: "0",
    [theme.breakpoints.down("md")]: {
      height: "auto",
    },
  }));

  const StyledInerGrid = styled(Grid)(({ theme }) => ({
    width: "60%",
    margin: "50px auto",
    display: "flex",
    justifyContent: "space-evenly",
    [theme.breakpoints.down("xl")]: {
      width: "80%",
      justifySelf: "center",
    },
  }));

  const StyledGridLogo = styled("h2")(({ theme }) => ({
    marginBottom: "0px",
    marginTop: "-10px",
    fontSize: "30px",
    fontWeight: 1000,
    textAlign: "center",
    justifySelf: "center",
    padding: "0px",
    [theme.breakpoints.down("md")]: {
      fontSize: "22px",
      textAlign: "left",
      justifySelf: "center",
      marginLeft: "0px",
      marginTop: "10px",
    },
  }));

  const StyledGridButton = styled(Button)(({ theme }) => ({
    backgoundColor: "#07163a",
    fontSize: "18px",
    fontWeight: 1000,
    display: "block",
    margin: " 0",
    textTransform: "capitalize",
    color: "white",
    textAlign: "center",
    padding: "2px 0",
    "&:hover": {
      color: "#888",
    },
    [theme.breakpoints.down("md")]: {
      fontWeight: 500,
    },
  }));

  const StyledImgLogo = styled("img")(() => ({
    marginRight: "5px",
    "&:hover": {
      opacity: 0.8,
      cursor: "pointer",
    },
  }));

  const StyledSpan = styled("span")(() => ({
    fontWeight: "normal",
    fontSize: "14px",
  }));

  const StyledLangIcon = styled("img")(() => ({
    padding: "0px",
    width: "20px",
    margin: "10px 0",
    cursor: "pointer",
  }));

  const goToContactForm = () => {
    // history.push("/");
    // setTimeout(() => {
    //   gsap.to(window, {
    //     duration: 2,
    //     scrollTo: "#contactForm",
    //   });
    // }, 500);
    window.open(
      `https://laligaland.io/contact/?lang=${i18n.language}`,
      "_blank"
    );
  };

  return (
    <StyledFooter container>
      <StyledInerGrid container justifyContent="space-evenly">
        <Grid
          item
          sm={3}
          xs={12}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Link to="/">
            <img src={VegasCity} alt="Laliga land logo" />
          </Link>
        </Grid>
        <Grid
          item
          sm={3}
          xs={12}
          sx={{ marginTop: { xs: "30px", md: "0" } }}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <StyledGridButton
            onClick={() => {
              if (i18n.language === "en") {
                window.open(
                  "https://laligaland.io/learn-and-play/?lang=en",
                  "_blank"
                );
              } else {
                window.open("https://laligaland.io/aprende/", "_blank");
              }
            }}
          >
            {t("learn")}
          </StyledGridButton>
          <StyledGridButton
            onClick={() => {
              if (i18n.language === "en") {
                window.open("https://laligaland.io/explore/?lang=en", "_blank");
              } else {
                window.open("https://laligaland.io/explore/", "_blank");
              }
            }}
          >
            {t("explore")}
          </StyledGridButton>
          <StyledGridButton
            onClick={() => {
              if (i18n.language === "en") {
                window.open("https://laligaland.io/news/?lang=en", "_blank");
              } else {
                window.open("https://laligaland.io/noticias/", "_blank");
              }
            }}
          >
            {t("news")}
          </StyledGridButton>
          {i18n.language === "es" ? (
            <StyledLangIcon
              src="/images/en.png"
              alt="EN"
              onClick={() => i18n.changeLanguage("en")}
            />
          ) : (
            <StyledLangIcon
              src="/images/es.png"
              alt="ES"
              onClick={() => i18n.changeLanguage("es")}
            />
          )}
        </Grid>
        <Grid
          item
          sm={3}
          xs={12}
          sx={{ marginTop: { xs: "30px", md: "0" } }}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <StyledGridButton onClick={goToContactForm}>
            {t("contact")}
          </StyledGridButton>
          <StyledGridButton
            onClick={() =>
              window.open(
                "https://laligaland.io/politica-de-privacidad/",
                "_blank"
              )
            }
          >
            {t("privacy")}
          </StyledGridButton>
          <StyledGridButton
            onClick={() =>
              window.open(
                "https://laligaland.io/terms-and-conditions/",
                "_blank"
              )
            }
          >
            {t("terms")}
          </StyledGridButton>
          <StyledGridButton
            onClick={() =>
              window.open("https://laligaland.io/legal-notice/", "_blank")
            }
          >
            {t("warning")}
          </StyledGridButton>
        </Grid>
        <Grid
          item
          sm={3}
          xs={12}
          sx={{ marginTop: { xs: "30px", md: "0" } }}
          direction="column"
          container
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <StyledGridLogo>{t("followUs")}</StyledGridLogo>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignSelf: { xs: "left", md: "center" },
            }}
          >
            <StyledImgLogo
              src={DiscordLogo}
              sx={{ width: "42px" }}
              onClick={() =>
                window.open("https://discord.gg/dTEBQZ32rY", "_blank")
              }
            />
            {/* <StyledImgLogo
              src={InstagramLogo}
              sx={{ width: "44px" }}
              onClick={() =>
                window.open(
                  "https://www.instagram.com/laligaland.io/",
                  "_blank"
                )
              }
            /> */}
            <StyledImgLogo
              src={TwitterLogo}
              sx={{ width: "34px" }}
              onClick={() =>
                window.open("https://twitter.com/LaLigaLand", "_blank")
              }
            />
            <StyledImgLogo
              src={YoutubeLogo}
              sx={{ width: "42px" }}
              onClick={() =>
                window.open(
                  "https://www.youtube.com/channel/UCJEhmGerwlLcEeoGT_dKjzA",
                  "_blank"
                )
              }
            />
          </Box>
        </Grid>
      </StyledInerGrid>
    </StyledFooter>
  );
};

export default NewFooter;
