//  Network Names
const LOCAL_HARDHAT = "LOCAL_HARDHAT";
const GOERLI = "GOERLI";
const MAINNET = "MAINNET";
const MUMBAI = "mumbai";

export const SUPPORTED_NETWORKS = [LOCAL_HARDHAT, GOERLI, MAINNET];

const hardhatNodeParams = {
  chainId: `0x${(31337).toString(16)}`,
  // chainId: 31337,
  rpcUrls: ["http://127.0.0.1:8545"],
  chainName: "Hardhat Node",
  nativeCurrency: { name: "ETH", decimals: 18, symbol: "ETH" },
  blockExplorerUrls: null,
};

const ropstenParams = {
  chainId: `0x${(3).toString(16)}`,
  rpcUrls: ["https://ropsten.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161"],
  chainName: "Ropsten",
  nativeCurrency: { name: "ETH", decimals: 18, symbol: "ETH" },
  blockExplorerUrls: ["https://ropsten.etherscan.io"],
};

const mumbaiParams = {
  chainId: `0x${(80001).toString(16)}`,
  rpcUrls: ["https://rpc.ankr.com/polygon_mumbai"],
  chainName: "Polygon Mumbai",
  nativeCurrency: { name: "MATIC", decimals: 18, symbol: "MATIC" },
  blockExplorerUrls: ["https://mumbai.polygonscan.com"],
  openseaUrl: "https://testnets.opensea.io/assets/mumbai",
};


const goerliParams = {
  chainId: `0x${(5).toString(16)}`,
  rpcUrls: ["https://goerli.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161"],
  chainName: "Goerli",
  nativeCurrency: { name: "GoerliETH", decimals: 18, symbol: "ETH" },
  blockExplorerUrls: ["https://goerli.etherscan.io"],
  openseaUrl: "https://testnets.opensea.io/assets/goerli",
};

const mainnetParams = {
  chainId: `0x${(1).toString(16)}`,
  rpcUrls: ["https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161"],
  chainName: "Ethereum Mainnet",
  nativeCurrency: { name: "ETH", decimals: 18, symbol: "ETH" },
  blockExplorerUrls: ["https://etherscan.io"],
  openseaUrl: "https://opensea.io/assets/ethereum",
};

export const chainIdToNetworkMapping = {
  31337: LOCAL_HARDHAT,
  5: GOERLI,
  1: MAINNET,
  80001: MUMBAI
};

export const chainIdToNetworkParams = {
  31337: hardhatNodeParams,
  5: goerliParams,
  1: mainnetParams,
  80001: mumbaiParams
};
