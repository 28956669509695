import { ethers, BigNumber } from "ethers";
import axios from "axios";
import * as vegasCityRewardNFT from "../VegasCityRewardNFT.json";
import * as Forwarder from "../MinimalForwarder.json"
import * as TestMANA from "../TestManaToken.json"
import { signMetaTxRequest } from "./signer";
require("dotenv").config();

const CONTRACT_ADDRESS = process.env.REACT_APP_VEGASCITY_REWARD_CONTRACT_ADDRESS;
const FORWARDER_ADDRESS = process.env.REACT_APP_VEGASCITY_FORWARDER_CONTRACT_ADDRESS;
const MANA_ADDRESS = process.env.REACT_APP_TEST_MANA_CONTRACT_ADDRESS;
const PROVIDER_RPC_URL = process.env.REACT_APP_PROVIDER;

const VEGAS_CITY_REWARD_NFT_ABI = vegasCityRewardNFT.abi;

const getVegasCityRewardNFTContract = () => {
  // A Web3Provider wraps a standard Web3 provider, which is
  // what MetaMask injects as window.ethereum into each page
  const provider = new ethers.providers.Web3Provider(window.ethereum);

  // The MetaMask plugin also allows signing transactions to
  // send ether and pay to change state within the blockchain.
  // For this, you need the account signer...
  const signer = provider.getSigner();

  const vegasCityRewardNFTContract = new ethers.Contract(
    CONTRACT_ADDRESS,
    VEGAS_CITY_REWARD_NFT_ABI,
    signer
  );

  return vegasCityRewardNFTContract;
};

const getVegasCityRewardNFTContractReadOnly = () => {
  const provider = new ethers.providers.JsonRpcProvider(PROVIDER_RPC_URL);

  const vegasCityRewardNFTContract = new ethers.Contract(
    CONTRACT_ADDRESS,
    VEGAS_CITY_REWARD_NFT_ABI,
    provider
  );

  return vegasCityRewardNFTContract;
};

const getForwarderContract = () => {
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const signer = provider.getSigner();

  const forwarderJsonAbi = Forwarder.abi;
  const forwarderContractAddress = FORWARDER_ADDRESS;

  const forwarderContract = new ethers.Contract(
    forwarderContractAddress,
    forwarderJsonAbi,
    signer
  );
  return forwarderContract;
};

const getMANAContract = () => {
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const signer = provider.getSigner();

  const manaJsonAbi = TestMANA.abi;
  const manaContractAddress = MANA_ADDRESS;

  const manaContract = new ethers.Contract(
    manaContractAddress,
    manaJsonAbi,
    signer
  );
  return manaContract;
};

const getMANAContractReadOnly = () => {
  const provider = new ethers.providers.JsonRpcProvider(process.env.REACT_APP_PROVIDER)
  const manaJsonAbi = TestMANA.abi;
  const manaContractAddress = MANA_ADDRESS;

  const manaContract = new ethers.Contract(
    manaContractAddress,
    manaJsonAbi,
    provider
  );
  return manaContract;
};

export const getForwarderContractReadOnly = async () => {
  const provider = new ethers.providers.JsonRpcProvider(process.env.REACT_APP_PROVIDER)
  const forwarderJsonAbi = Forwarder.abi;
  const forwarderContractAddress = FORWARDER_ADDRESS;

  const forwarderContract = new ethers.Contract(
    forwarderContractAddress,
    forwarderJsonAbi,
    provider
  )
  return forwarderContract;
}


const buyVegasCityRewardNFT = async (address, count) => {
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const signer = provider.getSigner();

  const vegasCityContract = getVegasCityRewardNFTContract();
  // const manaContract =  getMANAContractReadOnly()

  const PRICE_PER_NFT = await vegasCityContract.priceVegasCityRewardNFT();
  const actual_price = BigNumber.from(PRICE_PER_NFT).mul(count);
  // console.log(actual_price, 'actual price')

  try {
    return vegasCityContract.mintVegasCityRewardNFT(address, count);
  } catch (err) {
    return err;
  }

};

export const approveMANA = async (count) => {
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const signer = provider.getSigner();

  const vegasCityContract = getVegasCityRewardNFTContractReadOnly();
  const manaContract = getMANAContract()

  const PRICE_PER_NFT = await vegasCityContract.priceVegasCityRewardNFT();
  const actual_price = BigNumber.from(PRICE_PER_NFT).mul(count);
  // console.log(actual_price, 'actual price')

  try {
    return manaContract.approve(vegasCityContract.address, actual_price);
  } catch (err) {
    return err;
  }

};


export const buyVegasCityRewardNFTViaRelay = async (address, count) => {
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const signer = provider.getSigner();

  console.log('Trying to buy NFT via relay', address, count)

  const vegasCityContract = getVegasCityRewardNFTContract();

  const url = process.env.REACT_APP_OZ_DEFENDER_WEBOOK_URL;
  if (!url) throw new Error(`Missing relayer url`);

  const forwarder = getForwarderContract();
  const from = await signer.getAddress();
  const data = vegasCityContract.interface.encodeFunctionData('mintVegasCityRewardNFT', [address, count]);
  const to = vegasCityContract.address;

  console.log(forwarder)
  const request = await signMetaTxRequest(signer.provider, forwarder, { to, from, data });

  try {
    return fetch(url, {
      method: 'POST',
      body: JSON.stringify(request),
      headers: { 'Content-Type': 'application/json' },
    });
  } catch (err) {
    return err
  }

};


const buyVegasCityRewardNFTByReferral = async (address, count, referralId) => {
  const vegasCityContract = getVegasCityRewardNFTContract();

  const PRICE_PER_NFT = await vegasCityContract.priceVegasCityRewardNFT();
  const actual_price = BigNumber.from(PRICE_PER_NFT).mul(count);

  try {
    return vegasCityContract.mintVegasCityRewardNFTByReferral(
      address,
      count,
      Number(referralId),
      { value: actual_price }
    );
  } catch (err) {
    return err;
  }
};

const getReferees = async (referralId) => {
  const vegasCityContract = getVegasCityRewardNFTContractReadOnly();
  try {
    return vegasCityContract.getReferees(referralId);
  } catch (err) {
    return err;
  }
};

const getMintPrice = async () => {
  const vegasCityContract = getVegasCityRewardNFTContractReadOnly();
  try {
    const ethPrice = ethers.utils.formatUnits(
      await vegasCityContract.priceVegasCityRewardNFT()
    );
    const { data: fiatPrice } = await axios.get(
      "https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD,EUR&api_key=455406f3e93be85ceecfdc12e02aedf86ec0b26788ee0a514cbb6c42e33f8fd2"
    );
    return {
      ethPrice,
      usdPrice:
        fiatPrice?.Response === "Error"
          ? null
          : Math.round(ethPrice * fiatPrice.USD),
      euroPrice:
        fiatPrice?.Response === "Error"
          ? null
          : Math.round(ethPrice * fiatPrice.EUR),
    };
  } catch (err) {
    return null;
  }
};

const registerAsReferrer = async () => {
  const vegasCityContract = getVegasCityRewardNFTContract();
  try {
    return vegasCityContract.registerAsReferrer();
  } catch (err) {
    return err;
  }
};

const getReferrers = async () => {
  const vegasCityContract = getVegasCityRewardNFTContractReadOnly();
  const filter = vegasCityContract.filters.RegisterAsReferrer(null, null);
  const events = await vegasCityContract.queryFilter(filter);
};

const getReferrerFromReferralId = async (referralId) => {
  const vegasCityContract = getVegasCityRewardNFTContractReadOnly();
  const filter = vegasCityContract.filters.RegisterAsReferrer(null, referralId);
  const events = await vegasCityContract.queryFilter(filter);
  // console.log(events)
  const referrerAddress = events[0].topics[1];
  return referrerAddress;
};

// TODO: Check if a wallet can have only one referralId
// Right now multiple referralIds from a single wallet possible
const getReferralIdFromReferrer = async (referrerAddress) => {
  const vegasCityContract = getVegasCityRewardNFTContractReadOnly();
  const filter = vegasCityContract.filters.RegisterAsReferrer(referrerAddress, null);
  const events = await vegasCityContract.queryFilter(filter);
  const referralIds = [];
  for (let referralIdTx of events) {
    const referralId = referralIdTx.topics[2];
    referralIds.push(Number(referralId.toString()));
  }
  return referralIds[0];
};

const getMintsByReferralId = async (referralId) => {
  const vegasCityContract = getVegasCityRewardNFTContractReadOnly();
  const filter = vegasCityContract.filters.MintByReferral(referralId, null, null);
  const events = await vegasCityContract.queryFilter(filter);
  const mints = [];
  for (let mintTx of events) {
    const mint = {
      blockNumber: mintTx.blockNumber,
      count: Number(mintTx.args.count),
      referee: mintTx.args.referee,
      referralId: Number(mintTx.args.referralId),
      txHash: mintTx.transactionHash,
      commissionAmount: ethers.utils.formatUnits(mintTx.args.commissionAmount),
    };
    mints.push(mint);
  }
  return mints;
};

const getTokenTransfers = async (from = null, to = null, tokenId = null) => {
  const vegasCityContract = getVegasCityRewardNFTContractReadOnly();
  const filter = vegasCityContract.filters.Transfer(from, to, tokenId);
  const events = await vegasCityContract.queryFilter(filter);
  const tokens = [];
  for (let transferTx of events) {
    const token = {
      blockNumber: transferTx.blockNumber,
      from: transferTx.args.from,
      to: transferTx.args.to,
      tokenId: Number(transferTx.args.tokenId),
      txHash: transferTx.transactionHash,
    };
    tokens.push(token);
  }
  return tokens;
};

const getOwnerOfToken = async (tokenId) => {
  const vegasCityContract = getVegasCityRewardNFTContractReadOnly();
  const owner = await vegasCityContract.ownerOf(tokenId);
  console.log("Owner of tokenId", tokenId, "is", owner);
  return owner;
};

const latestUserTransfersForToken = (tokenTransfers) => {
  const tokenTransfersObjPerTokenId = {};
  for (let transferTx of tokenTransfers) {
    if (!tokenTransfersObjPerTokenId[transferTx.tokenId]) {
      tokenTransfersObjPerTokenId[transferTx.tokenId] = transferTx;
    } else if (
      tokenTransfersObjPerTokenId[transferTx.tokenId].blockNumber <
      transferTx.blockNumber
    ) {
      tokenTransfersObjPerTokenId[transferTx.tokenId] = transferTx;
    }
  }
  return tokenTransfersObjPerTokenId;
};

const getTokensOwnedByWalletAddress = async (walletAddress) => {
  const tokensReceived = latestUserTransfersForToken(
    await getTokenTransfers(null, walletAddress, null)
  );
  const tokensSent = latestUserTransfersForToken(
    await getTokenTransfers(walletAddress, null, null)
  );

  const tokensOwned = [];

  const tokenIds = Object.keys(tokensReceived);

  for (let tokenId of tokenIds) {
    if (!tokensSent[tokenId]) {
      tokensOwned.push(tokensReceived[tokenId]);
    } else {
      if (
        tokensReceived[tokenId].blockNumber > tokensSent[tokenId].blockNumber
      ) {
        tokensOwned.push(tokensReceived[tokenId]);
      }
    }
  }
  return tokensOwned;
};

export {
  CONTRACT_ADDRESS,
  buyVegasCityRewardNFT,
  buyVegasCityRewardNFTByReferral,
  getReferees,
  registerAsReferrer,
  getReferrers,
  getReferrerFromReferralId,
  getReferralIdFromReferrer,
  getMintsByReferralId,
  getMintPrice,
  getTokensOwnedByWalletAddress,
};
