import React from "react";

const LayoutDefault = ({ children }) => (
  <>
    <main
    // style={{
    //   backgroundColor: "transparent",
    //   backgroundImage: "linear-gradient(80deg, #ffb355 0%, #f2295b 100%)",
    //   transition: "background 0.3s, border-radius 0.3s, opacity 0.3s",
    // }}
    >
      <div className="elementor-background-video-container">
        <div
          style={{
            backgroundColor: "transparent",
            backgroundImage: "linear-gradient(80deg, #ffb355 0%, #f2295b 100%)",
            transition: "background 0.3s, border-radius 0.3s, opacity 0.3s",
            position: "fixed",
            width: "100vw",
            height: "100vh",
            opacity: "0.9",
            zIndex: "-90",
          }}
        ></div>
      </div>
      {children}
    </main>
  </>
);

export default LayoutDefault;
